.demoRequestForm {
  background-color: rgba(255, 255, 255, 0.65) !important;
  padding: 3rem 5rem !important;
}

.demoRequest-button {
  cursor: pointer;
  background-color: #e0551b !important;
  border: none !important;
  border-radius: 0.25rem;
}