.tou-content {
    padding: 0rem 5rem;
}
.tou-content>p {
    color: #30225f;
}

.tou-content>h1 {
    color: #30225f;
    text-align: center;
}

.tou-content>h2 {
    color: #30225f;
    text-align: center;
}

.tou-content>h3 {
    color: #30225f;
    text-align: left;
}

.tou-content>h4 {
    color: #30225f;
    text-align: left;
}

.tou-content>h5 {
    color: #30225f;
    text-align: left;
}

.tou-content>h6 {
    color: #30225f;
    text-align: left;
}

.tou-content>ul {
    color: #30225f;
    text-align: start;
    font-size: 1rem;
    /* margin-left: -1rem; */
    padding-left: 1rem;
}

.tou-content>p {
    text-align: justify;
    font-size: 1rem;
}

.tou-modal {
    top: 0;
    width: 100vw !important;
    margin: 0 auto !important;
    /* max-width: 100vw !important; */
}

@media only screen and (min-width: 992px) {
    .tou-modal {
        top: 32px;
        width: 70vw !important;
    }
}

@media only screen and (min-width: 1200px) {
    .tou-modal {
        top: 32px;
        width: 65vw !important;
    }
}


/* .ToU-page {
    height: 100vh;
    height: 100dvh;
    text-align: center;
}
.ToU-banner-filler {
    height: 13.3vh;
}
.ToU-banner {
  background: url(./assets/img/tou/banner_bubbles_bg.png);
  height: 30vh;
  min-height: 25vh;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100% auto;
  overflow: hidden;
  text-align: left;
  text-shadow: 3px 3px 4px rgba(0, 0, 0, 0.5);
}
.ToU-page-title {
    color: white;
    font-size: x-large;
}
.ToU-page-title h1 {
    padding-top: 1rem 5rem;
}
.ToU-banner-separator {
    background-color: var(--primary-color);
    height: 1vh;
} */