.login-layout {
    background: "#eef2f6";
}
.login-header-navbar {
    background: "#3c3066";
}
.login-form {
    max-width: 300px;
}

.login-form-forgot {
    float: right;
}

.ant-col-rtl .login-form-forgot {
    float: left;
}

.login-form-button {
    width: 100%;
}