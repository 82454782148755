.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1.25rem;
}

.navbar>.container-fluid {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between;
}

.container-fluid {
    width: 100%;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-right: auto;
    margin-left: auto;
}

.navbar-brand {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    text-decoration: none;
    white-space: nowrap;
}

.navbar-brand>a {
    display: inline-block;
    /* vertical-align: top; */
}

.nav-color {
    background-color: rgba(60, 48, 102, 0.85);
}

.sticky-top {
    position: sticky;
    top: 0;
    z-index: 1;
}

.nav-logo {
    height: clamp(2rem, 3rem, 5rem);
    pointer-events: none;
}

.nav-logo-text {
    height: clamp(1rem, 1.5rem, 2.5rem);
    pointer-events: none;
    /* opacity: 1; */
    transition-delay: 1s;
    transition-duration: 2s;
    transition-property: opacity;
    transition-timing-function: ease-in;
    margin-left: 0.5rem;
}

.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
}

.collapse:not(.show) {
    display: none;
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav-link {
    display: block;
    padding: 0.5rem 1rem;
    color: rgba(255, 255, 255, 1);
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

.nav-toggle-button {
    height: 40px;
    width: 56px;
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    transition: box-shadow 0.15s ease-in-out;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.55);
    border-color: rgba(255, 255, 255, 0.1);
    display: none;
}

.nav-toggle-button:focus:not(:focus-visible) {
    outline: 0;
}

.nav-toggle-button:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0.25rem;
}

.nav-toggle-button:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0.25rem;
}

.mobile-nav-bar {
    width: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    max-height: 100%;
    overflow: hidden;
    height: 324px;
}

.mobile-nav-collapse {
    width: 100%;
    display: none;
}

.nav-expand-enter {
    height: 0;
}

.nav-expand-enter-active {
    height: 460px;
    transition: all 300ms ease;
}

.nav-expand-enter-done {
    width: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    max-height: 100%;
    overflow: hidden;
    height: 460px;
}

.nav-expand-exit {
    height: 460px;
}

.nav-expand-exit-active {
    height: 0px !important;
    transition: all 300ms ease;
}

.nav-expand-exit-done {
    display: none!important;
}

@media (max-width: 1234.9px) {
    .nav-toggle-button {
        display: inline-block;
    }

    .mobile-nav-collapse {
        display: block;
    }

    .navbar-expand-lg .mobile-nav-bar {
        display: flex;
        flex: 1 1 auto;
    }

    .navbar-expand-lg .mobile-nav-bar {
        flex-direction: column;
    }

    .navbar-expand-lg .mobile-nav-bar {
        margin: auto;
    }
}

@media (min-width: 1235px) {
    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }

    .navbar-expand-lg .navbar-nav {
        flex-direction: row;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
}

@media (prefers-reduced-motion: no-preference) {
    .nav-logo {
        animation: nav-logo-spin 3s ease-in-out;
    }
}

@keyframes nav-logo-spin {
    from {
        transform: rotate(17deg);
    }

    to {
        transform: rotate(0deg);
    }
}