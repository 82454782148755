faq-collapse
faq-collapse faq-collapse-panel {
    margin-bottom: 24px;
    overflow: hidden;
    background: #dce;
    border: 0px;
    border-radius: 2px;
    font-weight: bold;
    font-size: x-large;
    color: #30225f;
}

.faq-wellness-continuum {
    height: 50%;
    max-width: 100%;
    pointer-events: none;
    margin-bottom: 1rem;
}