.dot-blue {
    height: 28px;
    width: 28px;
    background-color: #70c5e9;
    border-radius: 50%;
    display: inline-block;
}

.dot-purple {
    height: 28px;
    width: 28px;
    background-color: #6d61a5;
    border-radius: 50%;
    display: inline-block;
}

.dot-green {
    height: 28px;
    width: 28px;
    background-color: #397470;
    border-radius: 50%;
    display: inline-block;
}

.team-newcard {
  /* background: url(../../assets/images/team/card_bubbles_bg.png); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  /* filter: grayscale(100%); */
  transition: all 500ms ease;
  /* -webkit-box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.5); */
  /* box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.5); */
  /* text-align: center; */
  /* min-width: 18rem; */
  /* max-width: 20vw; */
}

/* .team-newcard:hover {
  width: 20rem;
  padding-top: 1rem;
  z-index: 99;
  transform: scale(1.1);
  transition: 500ms;
  filter: grayscale(0%);
  -webkit-box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.75);
} */

ul.bio {
  /* list-style-type: circle; */
  list-style-type: square;
  padding-left: 1.2em; 
  /* list-style-position: inside; */
  /* margin: 0; */
}