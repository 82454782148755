.survey-layout {
    background-color: #eef2f6;
}
.survey-acct-header {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
  
.dash-card {
    /* background: url(../../assets/images/team/card_bubbles_bg.png); */
    background-color: #8159f5;
    border-color: #8159f5;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    filter: grayscale(40%);
    /* opacity: 75%; */
    transition: all 500ms ease;
    -webkit-box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.5);
    border-radius: 15px;
}
.dash-card:hover {
    /* width: 20rem;
    padding-top: 1rem; */
    z-index: 99;
    transform: scale(1.2);
    filter: grayscale(0%);
    /* opacity: 100%; */
    -webkit-box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.75);
}
.dash-card .ant-card-body h3 {
    color: white;
}