.contactForm {
    background-color: rgba(255, 255, 255, 0.65) !important;
    padding: 3rem 5rem !important;
}

.footer-msg-button {
    cursor: pointer;
    background-color: #564d7c !important;
    border: none !important;
    border-radius: 0.6rem;
}