.sa-content {
  box-sizing: border-box;
  padding: 0 5rem;
}
.sa-content>p {
  color: #30225f;
}

.sa-content>h1 {
  color: #30225f;
  text-align: center;
}

.sa-content>h2 {
  color: #30225f;
  text-align: center;
}

.sa-content>h3 {
  color: #30225f;
  text-align: center;
  padding: 0rem 5rem;
  margin-top: 1rem;
}

.sa-content>h4 {
  color: #30225f;
  text-align: left;
}

.sa-content>h5 {
  color: #30225f;
  text-align: left;
}

.sa-content>h6 {
  color: #30225f;
  text-align: left;
}

.sa-content ol {
  color: #30225f;
  text-align: start;
  font-size: 1rem;
  counter-reset: item;
  padding-left: 1rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.sa-content ol > li {
  display: block;
  margin-bottom: 2rem;
  counter-increment: item;
  /* padding: 1rem 1rem; */
}
.sa-content ol > li:before {
  content: counters(item, ".") ". ";
  margin-left: -1rem;
}
.sa-content ol > li > ol > li {
  display: block;
  margin-bottom: 0.25rem;
  counter-increment: item;
  margin-left: 0rem;
  margin-top: 1rem;
}
.sa-content ol > li > ol > li:before {
  content: counters(item, ".") " ";
  margin-left: -1rem;
}
.sa-content>ul {
  color: #30225f;
  text-align: start;
  font-size: 1rem;
}
.sa-content>p {
  text-align: justify;
  font-size: 1rem;
  margin-top: 2rem;
}
.sa-content>ol>li>p {
  margin-top: 1rem;
}

@media only screen and (min-width: 992px) {
  .privacy-policy-modal {
      top: 32px;
      width: 70vw !important;
  }
}

@media only screen and (min-width: 1200px) {
  .privacy-policy-modal {
      top: 32px;
      width: 65vw !important;
  }
}