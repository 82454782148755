.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #3c409f;
  border-color: #3c409f;
}

.ant-checkbox-checked .ant-checkbox-inner :hover {
  border-color: #3c409f;
}

.ant-checkbox-checked .ant-checkbox-inner :focus {
    border-color: #3c409f;
  }

.ant-checkbox-checked::after {
  border-color: #3c409f;
}
