.footer {
    background: url(../assets/images/footer/footer-bg-circle.svg);
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: auto calc(100% - 50px);
    padding-bottom: 40px;
}

.footer-link-section {
    margin-top: 40px;
}

.footer-cta-text {
    font-family: 'Montserrat';
    font-size: 1.5rem;
    font-weight:bold00;
    text-align: center;
    color: white;
    line-height: 1.3;
}

.footer-link {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 18pt;
    display: block;
    padding: 0.5rem 0rem;
    color: #30225F;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

.footer-purple-box {
    min-height: 96px;
    background-color: rgba(48, 34, 95, .65);
    padding-top: 16px;
    padding-bottom: 4px;
}

.li-icon {
    background: url('../assets/images/logos/li-icon.svg');
    background-repeat: no-repeat;
    background-size: cover;

    display: inline-block;
    height: 40px;
    width: 40px;
}

.fb-icon {
    background: url('../assets/images/logos/fb-icon.svg');
    background-repeat: no-repeat;
    background-size: cover;

    display: inline-block;
    height: 40px;
    width: 40px;
}

.insta-icon {
    background: url('../assets/images/logos/insta-icon.svg');
    background-repeat: no-repeat;
    background-size: cover;

    display: inline-block;
    height: 40px;
    width: 40px;
}

.twitter-icon {
    background: url('../assets/images/logos/twitter-icon.svg');
    background-repeat: no-repeat;
    background-size: cover;

    display: inline-block;
    height: 40px;
    width: 40px;
}

.yt-icon {
    background: url('../assets/images/logos/yt-icon.svg');
    background-repeat: no-repeat;
    background-size: cover;

    display: inline-block;
    height: 40px;
    width: 40px;
}

.footer-icon-hstack {
    margin-top: 16px;
    margin-bottom: 16px;
}

.footer-bg-circle-img {
    max-height: 500px;
}

@media only screen and (min-width: 1200px) {
    .footer-icon-col {
        padding-top: 24px;
    }

    .footer-link-second-col {
        padding-left: 72px !important;
    }
}