@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  }
}

.App {
  text-align: left;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  /* color: #211c40; */
  color: #30225f;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.library-embed-video {
  aspect-ratio: 16 / 9;
  width: 100%;
}

.care-team-avatar:before {
  content: "";
  position: absolute;
  top: 0px; /* Adjust this value to set the spacing between the border and the circle */
  left: 0px;
  width: 100%; /* Adjust this value to include the border and the spacing */
  height: 100%;
  border: 3px solid #FFD600; /* Set the border width and color here */
  border-radius: 100%;
}

.ant-switch-checked {
  background-color: #3C3066;
}

.ant-steps-item-process .ant-steps-item-icon {
  background: #3C3066 !important;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #3C3066 !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
  border-color: #3C3066 !important;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #3C3066 !important;
}

.ant-steps-item-icon .ant-steps-icon {
  color: #3C3066 !important;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
  color: #fff!important;
}

.ant-steps-item-process .ant-steps-item-icon {
  border-color: #3C3066 !important;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #adaebd;
}

.ant-steps-item-title::after {
  height: 2px;
}

/* @media (min-width: 1279px) {
  .library-embed-video {
    display: block;
  }
  .library-video-link {
    display: none;
  }
}

@media (max-width: 1279px) {
  .library-embed-video {
    display: none;
  }
  .library-video-link {
    display: block;
  }
} */
.grecaptcha-badge { visibility: hidden; }