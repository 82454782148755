.mydash-layout {
  background: #eef2f6;
}

.mydash-acct-header {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

#client-dash-sider .ant-menu-item {
  color: #3c3066;
}
#client-dash-sider .ant-menu-item:hover {
  color: #44cff2;
}
#client-dash-sider .ant-menu-item-selected {
  background-color: rgba(26, 14, 66, 0.1) !important;
  font-weight: bold;
  color: #3c3066;
}
#client-dash-sider .ant-menu-item-selected::after {
  border-color: rgba(26, 14, 66, 0.5);
}
#client-dash-sider .ant-layout-sider-trigger {
  background-color: white;
  color: #10082d;
}
#client-dash-sider .ant-layout-sider-trigger:hover {
  background-color: #3c3066;
  color: white;
}
#client-dash-sider .ant-layout-sider-trigger .anticon-menu-unfold {
  transform: rotate(0deg);
  transition: transform 0.5s ease-out;
}
#client-dash-sider .ant-layout-sider-trigger:hover .anticon-menu-unfold {
  transform: rotate(180deg);
  transition: transform 0.5s ease-out;
}
#client-dash-sider.ant-layout-sider-collapsed
  .ant-layout-sider-trigger
  .anticon-menu-unfold {
  transform: rotate(180deg);
  transition: transform 0.5s ease-out;
}
#client-dash-sider.ant-layout-sider-collapsed
  .ant-layout-sider-trigger:hover
  .anticon-menu-unfold {
  transform: rotate(0deg);
  transition: transform 0.5s ease-out;
}

/* #client-profile-drawer .ant-drawer-content {
  background-color: #eef2f6f0;
}
#client-profile-drawer .ant-drawer-header {
  background-color: #eef2f6f0;
} */
/* #client-profile-drawer .ant-drawer-header-title .ant-drawer-close .anticon-close {
  transform: rotate(0deg);
  transition: transform 0.5s ease-out;
}
#client-profile-drawer .ant-drawer-header-title .ant-drawer-close:hover .anticon-close {
  transform: rotate(180deg);
  transition: transform 0.5s ease-out;
} */
.ant-drawer .ant-drawer-content {
  background-color: #eef2f6f0;
}
.ant-drawer .ant-drawer-header {
  background-color: #eef2f6f0;
}
.ant-drawer .ant-drawer-header-title .ant-drawer-close .anticon-close {
  transform: rotate(0deg);
  transition: transform 0.5s ease-out;
}
.ant-drawer .ant-drawer-header-title .ant-drawer-close:hover .anticon-close {
  transform: rotate(180deg);
  transition: transform 0.5s ease-out;
}
/* #client-profile-drawer .ant-space-item .ant-btn {
  background-color: #fffffff0;
  border-radius: 0.75rem;
  border-width: 0;
} */
/* #client-profile-drawer .ant-drawer-body .ant-divider-inner-text {
  color: #3c3066d0;
}
#client-profile-drawer .ant-drawer-body .ant-divider-with-text::before,
#client-profile-drawer .ant-drawer-body .ant-divider-with-text::after  {
  border-width: 0;
}
#client-profile-drawer .anticon-logout {
  transform: rotate(-180deg);
  transition: transform 0.5s ease-out;
  color: "#10082d";
}
#client-profile-drawer .ant-btn:hover .anticon-logout {
  transform: rotate(0deg);
  transition: transform 0.5s ease-out;
  color: "white" !important;
} */
.ant-drawer .ant-drawer-body .ant-divider-inner-text {
  color: #3c3066d0;
}

.ant-drawer .ant-drawer-body .ant-btn .anticon-edit {
  transform: rotateY(0deg);
  transition: transform 0.75s ease-in-out;
}
.ant-drawer .ant-drawer-body .ant-btn:hover .anticon-edit {
  transform: rotateY(180deg);
  transition: transform 0.75s ease-in-out;
}
.ant-drawer .anticon-logout {
  transform: rotate(-180deg);
  transition: transform 0.5s ease-out;
  color: "#10082d";
}
.ant-drawer .ant-btn:hover .anticon-logout {
  transform: rotate(0deg);
  transition: transform 0.5s ease-out;
  color: "white" !important;
}

#client-profile-drawer-circle .ant-btn {
  transform: scale(1);
  transition: transform 0.5s east-out;
}
#client-profile-drawer-circle .ant-btn:hover {
  transform: scale(1.25);
  transition: transform 0.5s east-out;
}

#client-profile-editor .ant-drawer-content {
  /* background-color: #eef2f6f0; */
  background-color: #eef2f6f0;
}
#client-profile-editor .ant-drawer-header {
  background-color: #eef2f6f0;
}
#client-profile-editor .ant-drawer-header-title .anticon {
  transform: rotate(0deg);
  transition: transform 0.5s ease-out;
}
#client-profile-editor .ant-drawer-header-title .anticon:hover {
  transform: rotate(180deg);
  transition: transform 0.5s ease-out;
}
#client-profile-editor .ant-drawer-body .ant-divider-with-text::before,
#client-profile-editor .ant-drawer-body .ant-divider-with-text::after {
  border-color: #3c306680;
}
