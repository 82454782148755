.Pricing-title {
  font-size: 56px;
  text-shadow: black 0.05em 0.05em 0.05em;
}

li.features {
  display: flex;
}

li.features-none::before {
  content: url("../../assets/images/pricing/check-icon-orange.svg");
  visibility: hidden;
  height: 24px;
  width: 24px;
  transform: translatex(-50%);
}

li.features-orange::before {
  content: url("../../assets/images/pricing/check-icon-orange.svg");
  height: 24px;
  width: 24px;
  transform: translatex(-50%);
}

li.features-red::before {
  content: url("../../assets/images/pricing/check-icon-red.svg");
  height: 24px;
  width: 24px;
  transform: translatex(-50%);
}
