.sccdash-layout {
  background-color: white;
  min-height: 100vh;
  min-height: 100dvh;
}

.sccdash-acct-header {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  background-color: #e0551bc0;
}

#sccdash-sider .ant-layout-sider-children {
    background-color: "white";
    height: "100%";
    border-right: 0;
}
#sccdash-sider .ant-menu-item {
  color: #3c3066;
}
#sccdash-sider .ant-menu-item:hover {
  color: #44cff2;
}
#sccdash-sider .ant-menu-item-selected {
  background-color: rgba(26, 14, 66, 0.1) !important;
  font-weight: bold;
  color: #3c3066;
}
#sccdash-sider .ant-menu-item-selected::after {
  border-color: rgba(26, 14, 66, 0.5);
}
#sccdash-sider .ant-layout-sider-trigger {
  background-color: white;
  color: #10082d;
}
#sccdash-sider .ant-layout-sider-trigger:hover {
  background-color: #3c3066;
  color: white;
}
#sccdash-sider .ant-layout-sider-trigger .anticon-menu-unfold {
  transform: rotate(0deg);
  transition: transform 0.5s ease-out;
}
#sccdash-sider .ant-layout-sider-trigger:hover .anticon-menu-unfold {
  transform: rotate(180deg);
  transition: transform 0.5s ease-out;
}
#sccdash-sider.ant-layout-sider-collapsed .ant-layout-sider-trigger .anticon-menu-unfold {
  transform: rotate(180deg);
  transition: transform 0.5s ease-out;
}
#sccdash-sider.ant-layout-sider-collapsed .ant-layout-sider-trigger:hover .anticon-menu-unfold {
  transform: rotate(0deg);
  transition: transform 0.5s ease-out;
}

/* #scc-profile-drawer .ant-drawer-content {
  background-color: #eef2f6f0;
}
#scc-profile-drawer .ant-drawer-header {
  background-color: #eef2f6f0;
} */
/* #scc-profile-drawer .ant-drawer-header-title .ant-drawer-close .anticon-close {
  transform: rotate(0deg);
  transition: transform 0.5s ease-out;
}
#scc-profile-drawer .ant-drawer-header-title .ant-drawer-close:hover .anticon-close {
  transform: rotate(180deg);
  transition: transform 0.5s ease-out;
} */
/* .ant-drawer .ant-drawer-content {
  background-color: #eef2f6f0;
}
.ant-drawer .ant-drawer-header {
  background-color: #eef2f6f0;
}
.ant-drawer .ant-drawer-header-title .ant-drawer-close .anticon-close {
  transform: rotate(0deg);
  transition: transform 0.5s ease-out;
}
.ant-drawer .ant-drawer-header-title .ant-drawer-close:hover .anticon-close {
  transform: rotate(180deg);
  transition: transform 0.5s ease-out;
} */
/* #scc-profile-drawer .ant-space-item .ant-btn {
  background-color: #fffffff0;
  border-radius: 0.75rem;
  border-width: 0;
} */
/* #scc-profile-drawer .ant-drawer-body .ant-divider-inner-text {
  color: #3c3066d0;
}
#scc-profile-drawer .ant-drawer-body .ant-divider-with-text::before,
#scc-profile-drawer .ant-drawer-body .ant-divider-with-text::after  {
  border-width: 0;
}
#scc-profile-drawer .anticon-logout {
  transform: rotate(-180deg);
  transition: transform 0.5s ease-out;
  color: "#10082d";
}
#scc-profile-drawer .ant-btn:hover .anticon-logout {
  transform: rotate(0deg);
  transition: transform 0.5s ease-out;
  color: "white" !important;
} */
/* .ant-drawer .ant-drawer-body .ant-divider-inner-text {
  color: #3c3066d0;
}
.ant-drawer .ant-drawer-body .ant-divider-with-text::before,
.ant-drawer .ant-drawer-body .ant-divider-with-text::after  {
  border-width: 0;
}
.ant-drawer .anticon-logout {
  transform: rotate(-180deg);
  transition: transform 0.5s ease-out;
  color: "#10082d";
}
.ant-drawer .ant-btn:hover .anticon-logout {
  transform: rotate(0deg);
  transition: transform 0.5s ease-out;
  color: "white" !important;
} */

#scc-profile-drawer-circle .ant-btn {
  transform: rotate(-90deg);
  transition: transform 0.5s east-out;
}
#scc-profile-drawer-circle .ant-btn:hover {
  transform: rotate(0deg);
  transition: transform 0.5s east-out;
}

#scc-profile-editor .ant-drawer-content {
  /* background-color: #eef2f6f0; */
  background-color: #eef2f6f0;
}
#scc-profile-editor .ant-drawer-header {
  background-color: #eef2f6f0;
}
#scc-profile-editor .ant-drawer-header-title .anticon {
  transform: rotate(0deg);
  transition: transform 0.5s ease-out;
}
#scc-profile-editor .ant-drawer-header-title .anticon:hover {
  transform: rotate(180deg);
  transition: transform 0.5s ease-out;
}
#scc-profile-editor .ant-drawer-body .ant-divider-with-text::before,
#scc-profile-editor .ant-drawer-body .ant-divider-with-text::after  {
  border-color: #3c306680;
}
