.How-we-can-help-why-us-item {
    text-align: start;
    margin-bottom: 16px;
}


@media only screen and (min-width: 768px) {
    .How-we-can-help-why-us-item:nth-child(even) {
        flex-direction: row-reverse;
        text-align: end;
    }
}