.radio-button {
  position: absolute;
  opacity: 0;
}

.check-mark {
  position: absolute;
  height: 25px;
  width: 25px;
  background-color: #211c40;
  border-radius: 50%;
}

.radio-input:hover .check-mark {
  background-color: #ea916b;
}

.radio-input input:checked ~ .check-mark {
  background-color: #e15726;
}

.check-mark:before {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background: white;
}

.radio-input input:checked ~ .check-mark:after {
  content: "";
  position: absolute;
  top: 8px;
  left: 8px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #211c40;
}

/* Chrome, Safari, Edge, Opera */
.no-arrow::-webkit-outer-spin-button,
.no-arrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.no-arrow[type="number"] {
  -moz-appearance: textfield;
}
