/*
  Who Are Caregivers styling
*/
/* .who-are-caregivers {
    background-image: url(../assets/images/cq-main-back.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size:contain;
    overflow: hidden;
} */
/*
  Testimonial carousel styling
*/
.jumbotron-testimonial {
    background-image: url(../assets/images/platform/platform-4-yourself-bubbles-bg.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    text-align: center;
    color: #30225f;
    /* padding-top: 3rem;
    padding-left: 6rem;
    padding-right: 6rem; */
    /* height: fit-content; */
    overflow: hidden;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.jumbotron-testimonial .carousel-control-prev-icon {
    width: 40px;
    height: 40px;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23c593d8' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.jumbotron-testimonial .carousel-control-next-icon {
    width: 40px;
    height: 40px;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23c593d8' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.jumbotron-testimonial-col {
    /* background-image: url(../assets/images/testimonials-bubbles-bg.svg);
    background-position: left;
    background-repeat: no-repeat;
    background-size: contain;
    overflow: hidden; */
    padding: 2rem 1rem 3rem 1rem;
}

.jumbotron-testimonial-col-L {
    background-color: rgba(255, 255, 255, 0.65);
    -webkit-box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.2);
    padding-top: 2rem;
    padding-right: 3rem;
    padding-bottom: 2rem;
    padding-left: 3rem;
    margin-top: 0rem;
    margin-left: 0rem;
    margin-right: 0rem;
    text-align: right;
}

.jumbotron-testimonial-col-R {
    background-color: rgba(255, 255, 255, 0.65);
    -webkit-box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.2);
    /* aspect-ratio: 1920/640; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    padding: 0rem;
    margin-top: 0rem;
    margin-left: 0rem;
}
.jumbotron-testimonial-col-R img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.jumbotron-testimonial-light {
    margin: 0rem;
    padding: 0rem;
}

.jumbotron-testimonial-dark h1,
.jumbotron-testimonial-dark h4,
.jumbotron-testimonial-dark h5 {
    text-align: left;
    color: white;
    margin: 0rem;
    padding: 0rem;
}

/*
  Platform overview styling
*/
.platform-container {
    min-height: 100vh;
    min-height: 100dvh;
    margin: 0rem;
    padding: 0rem;
}

.platform-header {
    background-color: white;
    height: 12.5vh;
    margin: 0rem;
    padding: 0rem;
}

.platform-overview {
    text-align: center;
    font-size: larger;
    min-height: 13.5vh;
    color: white;
}
.platform-overview h1 {
    color: white;
    padding: 1rem;
}
.platform-overview-LR {
    background-color: #6e6b83;
}

.platform-overview-C {
    background-color: #667fa0;
}

.platform-body {
    background-image: url(../assets/images/platform/platform-light-bubbles-bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 60vh;
    overflow: hidden;
    margin: 0rem;
    padding-left: 0rem;
}

.platform-body-L {
    /* width: 80%; */
    text-align: left;
}
.platform-body-L p {
    font-size: x-large;
}

.platform-body-R {
    background-image: url(../assets/images/platform/platform-dark-bubbles-bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    min-height: 60vh;
    font-size: x-large;
    font-weight:normal;
}

/*
  PlatformFor overview styling
*/
#platform-built4u {
    padding: 0rem;
}

.platform-for-container {
    margin: 0rem;
    padding: 0rem;
    width: 100%;
}

.platform-for-body-container {
    background-image: url(../assets/images/platform/platform-for-semicircle-bg.svg);
    background-position: left;
    background-repeat: no-repeat;
    background-size: 33vw;
    margin: 0rem;
    padding: 0rem;
}

.platform-for-row {
    /* height: 50vh; */
    /* height: auto; */
    margin: 0rem;
}

.platform-for-row-M {
    /* height: 50vh; */
    /* height: auto; */
    margin: 0rem;
}

.platform-for-col-L h1 {
    text-align: center;
    font-weight: bold;
}

.platform-for-col-RT {
    /* background-color: white; */
    padding-top: 1rem;
    padding-right: 5rem;
}
/* .platform-for-col-RT:before { */
    /* content: " ";
    display: block;
    position: absolute;
    left: 0;
    height: 100%;
    opacity: 0.5; */
    /* background-image: url(../assets/images/platform/platform-family-illustration.svg); */
    /* background-image: url(../assets/images/platform/caregiver-for-others.png);
    background-position: left;
    background-repeat: no-repeat;
    background-size:auto; */
    /* background-color: white;
    padding-top: 1rem;
    padding-right: 5rem;
} */

.platform-for-col-RT p {
    font-size: larger;
}

.platform-for-col-RM {
    background-image: url(../assets/images/platform/platform-4-yourself-bubbles-bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    /* padding-right: 10rem; */
}

.platform-for-col-RM p {
    font-size: larger;
}

.platform-for-col-RB {
    /* background-image: url(../assets/images/platform/platform-partners-illustration.svg); */
    /* background-image: url(../assets/images/platform/care-team.png);
    background-position: left;
    background-repeat: no-repeat;
    background-size: 10rem; */
    padding-top: 5rem;
    padding-right: 5rem;
}

.platform-for-col-RB p {
    font-size: larger;
}

/*
  WhyMCL carousel styling
*/
.whyMCL {
    background-image: url(../assets/images/platform/platform-4-yourself-bubbles-bg.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    text-align: center;
    color: #30225f;
    /* padding-top: 3rem;
    padding-left: 8rem;
    padding-right: 5rem;
    height: 100vh;
    height: 100dvh; */
    overflow: hidden;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.whyMCL .carousel-control-prev-icon {
    width: 40px;
    height: 40px;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23c593d8' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.whyMCL .carousel-control-next-icon {
    width: 40px;
    height: 40px;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23c593d8' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.whyMCL-col {
    padding: 0rem;
    text-align: justify;
}

.whyMCL-col-L {
    /* padding-top: 0rem;
    padding-right: 3rem !important;
    padding-left: 3rem !important;
    padding-bottom: 2rem; */
    margin: 0rem 1rem 2rem 2rem;
    text-align: justify;
    /* height: 100%;
    min-height: 50%; */
}

.whyMCL-col-R {
    background-color: rgba(255, 255, 255, 0.65);
    -webkit-box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.2);
    /* background-image: url(../assets/images/why/MCL-is-Affordable.jpg); */
    /* aspect-ratio: 1920/640; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    padding: 0rem;
    /* margin-top: 0rem;
    margin-left: 0rem; */
    margin: 0rem 1rem 2rem 2rem;
}

.whyNow-col-R {
    background-color: rgba(255, 255, 255, 0.65);
    -webkit-box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.2);
    /* background-image: url(../assets/images/why/helping-grandma.jpg);
    /* aspect-ratio: 1920/640; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    padding: 0rem;
    margin: 0rem 1rem 2rem 2rem;
}

.whyMCL-affordable-card {
    width: 100%;
    padding: 0rem 0rem !important;
}

.whyMCL-affordable-getStarted {
    background-color: #e0551b !important;
    border: none !important;
}

.MCLdelivers-col-R {
    background-color: rgba(255, 255, 255, 0.65);
    -webkit-box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.2);
    /* background-image: url(../assets/images/why/MCL-delivers.jpg);
    aspect-ratio: 1920/640; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    padding: 0rem;
    margin: 0rem 1rem 2rem 2rem;
}

.MCLSupportModel-col-R {
    background-color: rgba(255, 255, 255, 0.65);
    -webkit-box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.2);
    /* background-image: url(../assets/images/why/MCL-support.jpg);
    aspect-ratio: 1920/640; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    padding: 0rem;
    margin: 0rem 1rem 2rem 2rem;
}
.whyMCL-col-R img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.whyNow-col-R img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.MCLSupportModel-col-R img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.ant-collapse-content>.ant-collapse-content-box {
    padding: 0;
}

.ant-carousel .slick-prev::before {
    content: '<';
    display: block;
    position: relative;
    right: 3px;
    bottom: 20px;
    font-size: 25px;
    color: gray;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    width: 33px;
    height: 33px;

}

.slick-arrow.slick-next {
    font-size: 10px;
}

.ant-carousel .slick-next::before {
    content: '>';
    display: block;
    position: absolute;
    right: 0px;
    bottom: 20px;
    font-size: 25px;
    color: gray;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    width: 33px;
    height: 33px;
}

.slick-dots li {
    width: 30px!important;
    height: 6px!important;
    margin-right: 3px!important;
    margin-left: 3px!important;
}

.slick-dots li button {
    background: #000!important;
    opacity: 0.5;
}

.slick-dots li.slick-active {
    width: 30px!important;
}

.slick-dots li.slick-active button {
    opacity: 0;
}