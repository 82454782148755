.dash-card {
    margin-bottom: 1rem;
}
.dash-card:hover {
    /* width: 20rem;
    padding-top: 1rem; */
    z-index: 99;
    transform: scale(1.125);
    transition: 500ms;
    /* filter: grayscale(0%); */
}
.dash-card .ant-card-meta-title {
    text-align: center;
    font-size: larger;
    color: #fff;
}
.dash-card .ant-card-meta-description {
    text-align: center;
    font-size: small;
    color: #ffffffc0;
    line-height: 1.1em;
}