.vertical-divider {
  border-right: 4px solid #30225f;
}

.checkoutModal,
.checkoutModal .ant-modal-content {
  min-height: 700px;
  height: 90vh !important;
  width: 90vw !important;
  overflow-y: hidden;
}

.checkoutCollapse .ant-collapse-content {
  max-height: 25rem;
  overflow-y: scroll;
}
