.userinfoDatePicker input {
  font-weight: 700 !important;
  color: rgbA(16, 8, 47, 0.6) !important;
}

.userInfoStateSelect .ant-select-selection-item {
  font-weight: 700 !important;
  color: rgbA(16, 8, 47, 0.6) !important;
}

.userInfoStateSelect .ant-select-selection-placeholder {
    font-weight: 700 !important;
    color: rgbA(16, 8, 47, 0.6) !important;
  }
  