.team-card {
    background: url(../../assets/images/team/card_bubbles_bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    filter: grayscale(100%);
    transition: all 500ms ease;
    -webkit-box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.5);
    text-align: center;
    /* min-width: 18rem; */
    /* max-width: 20vw; */
}

.team-card:hover {
    /* width: 20rem;
    padding-top: 1rem; */
    z-index: 99;
    transform: scale(1.1);
    transition: 500ms;
    filter: grayscale(0%);
    -webkit-box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.75);}

.team-drawer .ant-drawer-content-wrapper {
    transition: all 2s ease;
}

.team-drawer .ant-drawer-header {
    background-color: #30225f;
    color: #fff;
    opacity: 67%;
    padding: 2rem;
    font-size: xx-large;
    font-weight: bold;
    align-items: center;
}

.team-drawer .ant-drawer-body {
    background-color: #30225f;
    color: #fff;
    opacity: 90%;
    padding: 3rem;
}