.panel-item {
    width: 50rem;
    margin-left: 2rem;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
}

.panel-subitem {
    width: 45rem;
    margin-left: 2rem;
}

.panel-item-no {
    display: inline-block;
    margin-right: 0.5rem;
}