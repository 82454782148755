.chat {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    margin-top: 20px;
}

.chat:hover {
    background-color: rgb(245, 243, 243);
}

.chatImg {
    /* width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px; */
    width: 20px;
    height: 20px;
    border-radius: 50%;
    /* object-fit: cover;*/
    margin-right: 20px;
}

.chatName {
    font-weight: 500;
}

@media screen and (max-width: 768px) {
    .chatName {
        display: none;
    }
}