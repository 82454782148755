.pp-content>p {
    color: #30225f;
}

.pp-content>h1 {
    color: #30225f;
    text-align: center;
}

.pp-content>h2 {
    color: #30225f;
    text-align: center;
}

.pp-content>h3 {
    color: #30225f;
    text-align: left;
}

.pp-content>h4 {
    color: #30225f;
    text-align: left;
}

.pp-content>h5 {
    color: #30225f;
    text-align: left;
}

.pp-content>h6 {
    color: #30225f;
    text-align: left;
}

.pp-content>ul {
    color: #30225f;
    text-align: start;
    font-size: 1rem;
}

/* .pp-content>h1 {
    text-align: center;
}

.pp-content>h2 {
    text-align: center;
}

.pp-content>h3 {
    text-align: center;
}

.pp-content>h4 {
    text-align: center;
}

.pp-content>h5 {
    text-align: center;
}

.pp-content>h6 {
    text-align: center;
} */

/* .pp-content>li {
    text-align: start;
    font-size: 1rem;
}
 */
.pp-content>p {
    text-align: justify;
    font-size: 1rem;
}

.privacy-policy-modal {
    top: 0;
    width: 100vw !important;
    margin: 0 auto !important;
    /* max-width: 100vw !important; */
}

@media only screen and (min-width: 992px) {
    .privacy-policy-modal {
        top: 32px;
        width: 70vw !important;
    }
}

@media only screen and (min-width: 1200px) {
    .privacy-policy-modal {
        top: 32px;
        width: 65vw !important;
    }
}

/* .ant-modal,
.ant-modal-content {
    min-height: 100vh;
    min-height: 100dvh;
    width: 100vw;
    margin: 0;
    top: 0;
}

.ant-modal-body {
    height: calc(100vh - 110px);
    height: calc(100dvh - 110px);
} */

/* .PP-page {
    height: 100vh;
    height: 100dvh;
    text-align: center;
} */
/* .PP-banner-filler {
    height: 13.3vh;
} */
/* .PP-banner {
  background: url(../../assets/images/background-bubbles.png);
  height: 30vh;
  min-height: 25vh;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100% auto;
  overflow: hidden;
  text-align: left;
  text-shadow: 3px 3px 4px rgba(0, 0, 0, 0.5);
} */
/* .PP-page-title {
    color: white;
    font-size: x-large;
}
.PP-page-title h1 {
    padding-top: 1rem 5rem;
}
.PP-banner-separator {
    background-color: var(--primary-color);
    height: 1vh;
} */